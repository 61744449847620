(function ($, app) {
    "use strict";

    app.m63 = function () {
        $('.academy-registration-form').parsley({
            errorClass: 'is-invalid',
            successClass: 'parsley-success',
            errorsWrapper: '<span class="text-help">',
            errorTemplate: '<div></div>'
          });

        function find_cookie(name) {
        var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
            if (match) {
                return match[2];
            } else {
                return 'empty';
            }
        }

        $(".academy-registration-form").on("submit", function(e){
            e.preventDefault();
            $('.form-loaded').addClass('d-none');
            $('.form-loading').removeClass('d-none');

            $(".academy-registration-form").parsley().whenValidate().done(function() {
                var newUserEmail 		= $('#email').val();
                var newUserPhone 		= $('#phone').val();
                var newUserCompany 	    = $('#company').val();
                var newUserPassword 	= $('#password').val();
                var newUserFirstName 	= $('#firstname').val();
                var newUserLastname 	= $('#lastname').val();
                // HS
                var hsutk             = find_cookie("hubspotutk");
                var FTcampaign        = find_cookie("pr_ft_utm_campaign");
                var FTcontent         = find_cookie("pr_ft_utm_content");
                var FTmedium          = find_cookie("pr_ft_utm_medium");
                var FTsource          = find_cookie("pr_ft_utm_source");
                var FTterm            = find_cookie("pr_ft_utm_term");
                var LTcampaign        = find_cookie("pr_lt_utm_campaign");
                var LTcontent         = find_cookie("pr_lt_utm_content");
                var LTmedium          = find_cookie("pr_lt_utm_medium");
                var LTsource          = find_cookie("pr_lt_utm_source");
                var LTterm            = find_cookie("pr_lt_utm_term");
                var source            = find_cookie("source");
                var ad_id_kwd         = find_cookie("ad_id_kwd");

                // AJAX
                jQuery.ajax({
                    type: "POST",
                    url : 'https://academy.planradar.com/wp-admin/admin-ajax.php',
                    data: {
                      action: "register_user_front_end",
                      new_user_name       : newUserEmail,
                      new_user_email      : newUserEmail,
                      new_user_phone      : newUserPhone,
                      new_user_company    : newUserCompany,
                      new_user_password   : newUserPassword,
                      new_user_first_name : newUserFirstName,
                      new_user_last_name  : newUserLastname,
                    },
                    success: function(results){
                        if (results == 'created') {
                          window.dataLayer = window.dataLayer || [];
                            window.dataLayer.push({
                            'event': 'academy_registrant'
                            });
                            //hsform
                            let hs_form = {
                                "fields": [
                                  {
                                    "name": "firstname",
                                    "value": newUserFirstName
                                  },
                                  {
                                    "name": "lastname",
                                    "value": newUserLastname
                                  },
                                  {
                                    "name": "email",
                                    "value": newUserEmail
                                  },
                                  {
                                    "name": "phone",
                                    "value": newUserPhone
                                  },
                                  {
                                    "name": "company",
                                    "value": newUserCompany
                                  },
                                  {
                                    "name": "campaign_first_touch_",
                                    "value": FTcampaign
                                  },
                                  {
                                    "name": "content_first_touch_",
                                    "value": FTcontent
                                  },
                                  {
                                    "name": "medium_first_touch_",
                                    "value": FTmedium
                                  },
                                  {
                                    "name": "source_first_touch_",
                                    "value": FTsource
                                  },
                                  {
                                    "name": "term_first_touch_",
                                    "value": FTterm
                                  },
                                  {
                                    "name": "campaign_last_touch_",
                                    "value": LTcampaign
                                  },
                                  {
                                    "name": "content_last_touch_",
                                    "value": LTcontent
                                  },
                                  {
                                    "name": "medium_last_touch_1",
                                    "value": LTmedium
                                  },
                                  {
                                    "name": "source_last_touch_",
                                    "value": LTsource
                                  },
                                  {
                                    "name": "term_last_touch_",
                                    "value": LTterm
                                  },
                                  {
                                    "name": "lead_source_detail",
                                    "value": source
                                  },
                                  {
                                    "name": "ad_id_keyword",
                                    "value": ad_id_kwd
                                  }
                                ],
                                "context": {
                                  "hutk"    : hsutk, // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
                                  "pageUri" : window.location.href,
                                  "pageName": "Academy Registration"
                                },
                              }
                              let hs_url = "https://api.hsforms.com/submissions/v3/integration/submit/7061715/ba95346d-876c-416b-bc0a-79b4d2c16181"
                              let hs_form_data = JSON.stringify(hs_form)
                              $.ajax({
                                type: "POST",
                                beforeSend: function(xhr) {
                                  xhr.setRequestHeader( "Content-type", "application/json" );
                                },
                                url: hs_url,
                                data: hs_form_data,
                                dataType: "json"
                         }).always(function(){
                          jQuery('.form-loading').addClass('d-none');
                          jQuery('.account-created').removeClass('d-none');
                          })
                        } else if (results == 'email') {
                          jQuery('.academy-registration-form').removeClass('d-none');
                          jQuery('.form-loading').addClass('d-none');
                          jQuery('#email').parsley().addError('forcederror', {message: emailError , updateClass: true});
                
                        }
                      },
                      error: function(results) {
                        console.log('error');
                      }
                    });

                    jQuery('#email').on('keyup', function() {
                        jQuery('#email').parsley().removeError('forcederror', {updateClass: true});
                    });
            });
        });

    };
})(jQuery, app);