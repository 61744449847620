(function ($, app) {
    "use strict";

    app.m59 = function () {
        $('.m59 .numbers .facts .number').each(function(){
            let numChars = $.trim($(this).text()).length; 

            if(numChars > 5){
                $('.m59 .numbers .facts .number').css("font-size", "25px");
            } 
        });
    };
})(jQuery, app); 