/* responsive module */

(function ($, app) {
    "use strict";

    app.m01c = function () {
        if($(".m01c .form.m69").length){
            if (hsFormID == '91894f15-5eeb-4b66-ba9a-51146a4aa9a2') {
                window.addEventListener('message', event => {
                    if(event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormReady') {
                        $('.hs_country input').val(country);
                    }
                });
                window.addEventListener('message', event => {
                    if(event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmitted') {
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({
                            'event': 'book-a-demo'
                        });
                    }
                });
            }
        }

    };
})(jQuery, app);