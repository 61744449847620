(function ($, app) {
    "use strict";

    app.m108 = function () {
        if($(".questionnaire").length){
            // checking height
            // var tallQ = 0;
            // $('.questions-list .question').each(function() { 
            //     if ($(this).outerHeight() > tallQ) {
            //         tallQ = $(this).outerHeight();   
            //      }
            // });
            // applying height + hide
            $('.questions-list .question').each(function() {
                // $(this).css({'min-height': tallQ+'px', 'visibility' : 'visible'}).addClass('d-none');
                // $('.questions-list').css('height', 'inherit');
                $(this).addClass('d-none');

            });
            


            $('.questions-list .question:first-child').removeClass('d-none').addClass('show');
            $('.indicator ul li:first-child').addClass('done');
            $('.questions-nav button.next').on('click', function() {
                var active = $('.questions-list .question.show');
                if (!$(active.find("input:checked")).val()) {
                    active.find('p:first-of-type').addClass('error');
                } else {
                    $(this).find('>p').removeClass('error');
                    active.removeClass('show').addClass('d-none');
                    active.next().removeClass('d-none').addClass('show');
                    if (active.next().is(':last-of-type')) {
                        $(this).addClass('d-none');
                        $(this).next().removeClass('d-none');
                    }
                    $('.indicator ul li.done').next().addClass('done');
                    var count = $('.questions-list .question.show').index() + 1;
                    if (count != ind_count) {
                        $('.indicator > p > span:first-of-type').text(count);
                    } else {
                        $('.indicator > p').text(last_question);
                    }
                }

            });
            
            $('.questions-nav button.show-results').on('click', function() {
                var active = $('.questions-list .question.show');
                if (!$(active.find("input:checked")).val()) {
                    active.find('p:first-of-type').addClass('error');
                } else {
                    var results = '';
                    $('.questions-list .question').each(function() {
                        results += '<div class="answers">';
                        results += '<p class="q">'+ $(this).find(">p:first-of-type").text()+ '</p>';
                        var checkedLabel = $(this).find("input[type=radio]:checked").attr('id');
                        results += '<div class="pr-form-check pr-form-radio">';
                        results += '<input class="pr-form-check-input" type="radio" checked>';
                        results += '<label class="pr-form-check-label">'+$(this).find('label[for="'+ checkedLabel +'"]').text()+'</label>'
                        results += '</div>';
                        results += '<p class="a">'+ $(this).find('.'+checkedLabel).next().html() +'</p>';
                        results += '</div>';
                    });
                    $('.questions-results').html(results);
                    $('.questions, .content').addClass('d-none');
                    $('.results, .form').removeClass('d-none');
                }
            });




        }
    };
})(jQuery, app);