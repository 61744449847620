(function ($, app) {
  "use strict";

  app.m25 = function () {

    $('.playBTN').on("click",function(){
        $(this).next().trigger('play');
        $(this).next().attr('controls',true);
        $(this).addClass('d-none')
    });


    const autoPlyr = Array.from(document.querySelectorAll('.m25-player2')).map((p) => new Plyr(p,{
        autoplay: true,
        muted: true,
        debug: true,
        controls: [ 
            'play', 
            'progress',  
            'fullscreen',
        ],
    }));
    autoPlyr.forEach(function(player) {
      player.on('ready', event => {
        player.muted=true;
        player.play();
      });
      player.on('playing', event => {
        $('.pr_plyr_loading').addClass('d-none')
      });
    });

    if(typeof players !== 'undefined'){
      players = Array.from(document.querySelectorAll('.m25-player')).map((p) => new Plyr(p,{
          controls: [ 
              'play-large',
              'play', 
              'progress', 
              'current-time', 
              'mute', 
              'volume', 
              'pip', 
              'airplay', 
              'fullscreen'
          ],
      }));
      
  }else{
      const players = Array.from(document.querySelectorAll('.m25-player')).map((p) => new Plyr(p,{
          controls: [ 
              'play-large',
              'play', 
              'progress', 
              'current-time', 
              'mute', 
              'volume', 
              'pip', 
              'airplay', 
              'fullscreen'
          ],
      }));
      
  }


      




    
  };
})(jQuery, app);