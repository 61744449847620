(function ($, app) {
    "use strict";

    app.m32 = function () {
      function rtl_slick(){
        if ($('html')[0].dir == 'rtl') {
           return true;
        } else {
           return false;
        }
      }
        $('.m32 .stories').slick({
            rtl: rtl_slick(),
            slidesToShow: 3,
            slidesToScroll: 1,
            dots: true,
            infinite: true,
            speed: 300,
            autoplay: false,
            arrows: false,
            variableWidth: false,
            focusOnSelect: false,
            centerMode: true,
            swipeToSlide: true,
            centerPadding: '10%',
            responsive: [
                {
                  breakpoint: 1200,
                  settings: {
                    slidesToShow: 1,
                    variableWidth: false,
                    focusOnSelect: false,
                    centerMode: true,
                    arrows: false,
                    centerPadding: '20%',
                  }
                },
                {
                  breakpoint: 500,
                  settings: {
                    slidesToShow: 1,
                    variableWidth: false,
                    focusOnSelect: false,
                    centerMode: true,
                    arrows: false,
                    centerPadding: '10%',
                  }
                },
            ]
        });
        $('.m32 .read-more').on('click', function(){
          let $less = $(this).data('less');
          let $more = $(this).data('more');
          if($(this).prev('.description').hasClass('expanded')){
            $(this).prev('.description').removeClass('expanded');
            $(this).html($more);
          }else{
            $(this).prev('.description').addClass('expanded');
            $(this).html($less);
          }

        });
    };
})(jQuery, app);