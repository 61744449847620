(function ($, app) {
    "use strict";

    app.m20 = function () {

        // $(document).on('change','input[name="cycle"]',function() {
        //     let $this = $(this);
        //     let $val = $this.val();
        //     console.log('calc-cycle',$val);
        //     $(this).closest('.m20').find('.price.show').each(function(){
        //         $(this).removeClass('show');
        //     });
        //     $(this).closest('.m20').find('.price-'+$val).addClass('show');
        // });
        // $(document).on('change','input.calc-cycle',function() {
        //     let $this = $(this);
        //     let $val = $this.val();
        //     console.log('calc-cycle',$val);
        //     $(this).closest('.prices').find('.price.show').each(function(){
        //         $(this).removeClass('show');
        //     });
        //      $(this).closest('.prices').find('.price-'+$val).addClass('show');
        // });
        // $(document).on('click','.calc-cycle-label',function() {
        //     let $this = $(this);
        //     $('.calc-cycle-label input[name="calc-cycle"]:checked').attr('checked','');
        //     $this.closest('input[name="calc-cycle"]').attr('checked','checked');
        //     console.log('clicked',$this.closest('input[name="calc-cycle"]'));
        // });




        $('.billing').on("click",function(){
            let $this = $(this);
            let $val = $this.attr('data-cycle');
            $('.selected_cycle').removeClass('yearlyPlan monthlyPlan').addClass($val+'Plan')
            $('.m20, .m22').find('.price.show').each(function(){
                $(this).removeClass('show');
            });
            $('.m20, .m22').find('.price-'+$val).addClass('show');
        });


    //    $(document).on('change','input[name="cycle"]',function() {
    //         let $this = $(this);
    //         let $val = $this.val();
    //         console.log('calc-cycle',$val);
    //         $(this).closest('.m20').find('.price.show').each(function(){
    //             $(this).removeClass('show');
    //         });
    //         $(this).closest('.m20').find('.price-'+$val).addClass('show');
    //     });
    //     $(document).on('change','input.calc-cycle',function() {
    //         let $this = $(this);
    //         let $val = $this.val();
    //         console.log('calc-cycle',$val);
    //         $(this).closest('.prices').find('.price.show').each(function(){
    //             $(this).removeClass('show');
    //         });
    //          $(this).closest('.prices').find('.price-'+$val).addClass('show');
    //     });
    //     $(document).on('click','.calc-cycle-label',function() {
    //         let $this = $(this);
    //         $('.calc-cycle-label input[name="calc-cycle"]:checked').attr('checked','');
    //         $this.closest('input[name="calc-cycle"]').attr('checked','checked');
    //         console.log('clicked',$this.closest('input[name="calc-cycle"]'));
    //     });









        $(document).on('mouseover','#currency-switcher',function(){
            if(window.innerWidth >= 1200){
                $(this).next('.currencies').addClass('show');
            }
        });
        $(document).on('click','#currency-switcher',function(){
            if(window.innerWidth < 1200){
                $(this).next('.currencies').addClass('show');
            }
        });
        $(document).on('mouseleave','.currencies.show',function(){
            $('.currencies').removeClass('show');
        });
        $(document).on('click','.currencies li',function(){
            let $this = $(this);
            let $val = $this.data('value');
            let $label = $this.html();
            let $sign = $this.data('sign');
            $('.currencies li.selected').removeClass('selected');
            $this.addClass('selected');
            $('button[name="currency-switcher"]').val($val);
            $('button[name="currency-switcher"]').html($label);
            $('button[name="currency-switcher"]').attr('data-sign',$sign);
            $('.currency').html($sign);
            $('.currencies').removeClass('show');
            $('.prices-currency.show').each(function(){
                $(this).removeClass('show');
            });
            $('.prices-currency.currency-'+$val).addClass('show');
        });
        
    };
})(jQuery, app);