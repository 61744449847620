(function ($, app) {
    "use strict";

    app.author = function () {
        
        $('.author-picks .slides').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            infinite: true,
            speed: 1500,
            autoplay: true,
            autoplaySpeed: 3500,
            arrows: true,
            responsive: [
              {
                breakpoint: 768,
                settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                }
              }
            ]
        });
    };
})(jQuery, app);