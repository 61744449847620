(function ($, app) {
    "use strict";

    app.m12 = function () {
        if (document.querySelector('.greybox') !== null) {
            $('.greybox').on('click', function() {
                $('.greybox').not(this).removeClass('opened');
                $(this).toggleClass('opened');
            })
        }

    };
})(jQuery, app);