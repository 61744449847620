(function($) {

    $(document).ready(function() {

        var $win = $(window);

        var elementTop, elementBottom, viewportTop, viewportBottom;

        function isScrolledIntoView(elem) {
            elementTop = $(elem).offset().top;
            elementBottom = elementTop + $(elem).outerHeight();
            viewportTop = $win.scrollTop();
            viewportBottom = viewportTop + $win.height() - 200;
            return (elementBottom > viewportTop && elementTop < viewportBottom);
        }

        if($('.m14 video, .m85 video').length){

            var loadVideo;

            $('.m14 video, .m85 video').each(function(){
                $(this).attr('webkit-playsinline', '');
                $(this).attr('playsinline', '');
                $(this).attr('muted', 'muted');

                $(this).attr('id','loadvideo');
                loadVideo = document.getElementById('loadvideo');
                loadVideo.load();
            });

            $win.scroll(function () { // video to play when is on viewport

                $('.m14 video, .m85 video').each(function(){
                    if (isScrolledIntoView(this) == true) {
                        $(this)[0].play();
                    } else {
                        $(this)[0].pause();
                    }
                });

            });
        }
    });

})(jQuery);