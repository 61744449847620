(function ($, app) {
    "use strict";

    app.m44 = function () {
        if($(".m44-player").length){
            var players;
            if(typeof players !== 'undefined'){
                const players = Array.from(document.querySelectorAll('.m44-player')).map((p) => new Plyr(p,{
                    playsinline: true,
                    controls: [ 
                        'play', 
                        'progress', 
                        'current-time', 
                        'mute', 
                        'volume', 
                        'pip', 
                        'airplay', 
                        'fullscreen'
                    ],
                }));
            }else{
                players = Array.from(document.querySelectorAll('.m44-player')).map((p) => new Plyr(p,{
                    playsinline: true,
                    controls: [ 
                        'play', 
                        'progress', 
                        'current-time', 
                        'mute', 
                        'volume', 
                        'pip', 
                        'airplay', 
                        'fullscreen'
                    ],
                }));
            }
            if ($("#player").data("autoplay") === true) {
                players.forEach(function(player) {
                    player.on('ready', event => {
                      player.muted=true;
                      player.play();
                    });
                });
            }

            


        }
    };
})(jQuery, app);