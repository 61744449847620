(function ($, app) {
    "use strict";

    app.m51 = function () {
        $(document).on('click','.cat',function(){
            let $target = $(this).data('target');
            $('.cat.active').removeClass('active');
            $('.category.active').removeClass('active');
            $(this).addClass('active');
            $('#'+$target).addClass('active');
        });
        if (document.querySelector('.m51') !== null) {
            $('.mob-cat').html($('.cat.active').text());
        }
        $(document).on('click','.mob-cat',function(){
            $('.mob-cats').addClass('show');
        });
        $(document).on('click','.mob-cats li',function(){
            let $target = $(this).data('target');
            let $value = $(this).data('value');
            $('.mob-cat').html($value);
            $('.mob-cats li.active').removeClass('active');
            $(this).addClass('active');
            $('.category.active').removeClass('active');
            $('.category#'+$target).addClass('active');
            $('.mob-cats').removeClass('show');
        });
        $(document).on('click','.languages li .dropdown-item',function(){
            let $this = $(this);
            let $val = $this.data('value');
            let $label = $this.html();
            $('.category.active .languages li.selected').removeClass('selected');
            $this.addClass('selected');
            $('.category.active #languages').html($label);
            $('.category.active .article.'+$val).each(function(){
                $(this).removeClass('hide');
                $(this).addClass('show');
            });
            $('.category.active .article:not(.'+$val+')').each(function(){
                $(this).removeClass('show');
                $(this).addClass('hide');
            });
        });
    };
})(jQuery, app);