(function ($, app) {
    "use strict";

    app.m73 = function () {

        if(window.innerWidth < 992){
            $('.items-slider .items').each(function(){
                $(this).slick({
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: true,
                    prevArrow: $(this).prev(),
                    nextArrow: $(this).next(),
                    autoplay: true,
                    autoplaySpeed: 6000,
                });
            });
        }
    };
})(jQuery, app);