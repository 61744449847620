/*
Calculations:

Hours saved per year: ({Number of Users} * 7) * 52

Money saved per year: (({Number of Users} * 7) * 52) * {Hourly rate}

Weeks until PlanRadar paid off: ({Number of users} * (89*12))/({Number of Users} * 7) * {Hourly rate}
*/
(function ($, app) {
    "use strict";

    app.m22 = function () {

      $(document).on('click','#industry',function(){
        let $this = $(this);
        $this.addClass('expanded');
        $('.industry').addClass('show');
        // console.log('mouseenter');
        return false;
      });

      // $(document).on('mouseleave','.industry.show',function(){
      //   $('.industry').removeClass('show');
      // });

      $(document).on('click','.industry li',function(e){
        e.preventDefault();
        let $this = $(this);
        let $val = $this.data('value');
        let $rate = $this.data('rate');
        $('.industry li.selected').removeClass('selected');
        $this.addClass('selected');
        $('#industry').val($val);
        $('#industry').data('rate',$rate);
        $('#industry').html($val);
        $('.industry').removeClass('show');
        $('#industry').removeClass('expanded');
        $('.results').removeClass('show');
      });



      var selector = '[data-rangeslider]';
      var $element = $(selector);

      $(document).on('input', 'input[type="range"], ' + selector, function() {
        let $this = $(this);
        let $value = $this.val();
        if($this.hasClass('users_range')){
          $('#users').val($value);
        }else if($this.hasClass('rate_range')){
          $('#rate').val($value);
        }else if($this.hasClass('plans_range')){
          $('#plans').val($value);
        }
          
      });
      function rtl_slick(){
        if ($('body').hasClass("ae")) {
            return true;
        } else {
            return false;
        }
      }
      $element.rangeslider({
        polyfill: false,
        isRTL: rtl_slick(),
        onInit: function() {
          let $this = this.$element[0];
          let $value = $this.value;
          let $class = $this.className;
          if($class.includes('users_range')){
            $('#users').val($value);
          }else if($class.includes('rate_range')){
            $('#rate').val($value);
          }else if($class.includes('plans_range')){
            $('#plans').val($value);
          }
        },

        // Callback function
        onSlide: function(position, value) {
          let $this = this.$element[0];
          let $class = $this.className;
          if($class.includes('users_range')){
            $('#users').val(value);
          }else if($class.includes('rate_range')){
            $('#rate').val(value);
          }else if($class.includes('plans_range')){
            $('#plans').val(value);
          }
        },

        // Callback function
        onSlideEnd: function(position, value) {
          let $this = this.$element[0];
          let $class = $this.className;
          if($class.includes('users_range')){
            $('#users').val(value);
          }else if($class.includes('rate_range')){
            $('#rate').val(value);
          }else if($class.includes('plans_range')){
            $('#plans').val(value);
          }
        }
      });
      
      $('.pricing_min').click(function() {
        var minusOne = $(this).next().val() - 1;
        var min = $(this).next().data('min');
        if(minusOne==min){
          $(this).addClass('disabled');
        }else{
          $(this).removeClass('disabled');
        }
        $(this).next().val(minusOne);
        $(this).parent().next().children('.pr_range').val(minusOne).change();
      });
      $('.pricing_plus').click(function() {
        var plusOne = parseInt($(this).prev().val()) + 1;
        var max = $(this).next().data('max');
        if(plusOne==max){
          $(this).addClass('disabled');
        }else{
          $(this).removeClass('disabled');
        }
        $(this).prev().val(plusOne);
        $(this).parent().next().children('.pr_range').val(plusOne).change();
      });

      //calculation
      $('.calculator').on('submit',function(){
        
        let $industry = $('#industry').data('rate');
        let $industry_val = $('#industry').val();
        let $industry_rate = ($industry>=7)? '7+': '7';
        let $currency = $('.currencies li.selected').data('sign');
        let $users = $('.users_range').val();
        let $rate = $('.rate_range').val();
        let $plans = $('.plans_range').val();
        let $hour_saving = $('.calc-hours').data('saving-more')+' ';
        
        //get plan yearly cost based on no. of users, no. of plans, currency
        let $planVal = 1;
        if($users == 1 && $plans <= 10){
          $planVal = $('.basic .prices-currency.show').data('rate');

          $('.note, .basic,.dasar, .starter').removeClass('d-none');
          $('.pro, .enterprise').addClass('d-none');
        }else if($users == 1 && $plans > 10 && $plans <= 30){
          $planVal = $('.starter .prices-currency.show').data('rate');

          $('.note,.dasar, .starter, .pro').removeClass('d-none');
          $('.basic, .enterprise').addClass('d-none');
        }else if($users == 1 && $plans > 30 && $plans <= 100){
          $planVal = $('.pro .prices-currency.show').data('rate');

          $('.note, .dasar, .pro, .enterprise').removeClass('d-none');
          $('.basic, .starter').addClass('d-none');
        }else if($users == 1 && $plans > 100){
          $planVal = $('.pro .prices-currency.show').data('rate');

          $('.enterprise').removeClass('d-none');
          $('.note, .basic,.dasar, .pro, .starter').addClass('d-none');
        }else if($users > 1 && $plans <= $users * 30){
          $planVal = $('.starter .prices-currency.show').data('rate');

          $('.note, .pro, .starter').removeClass('d-none');
          $('.basic,.dasar, .enterprise').addClass('d-none');
        }else if($users > 1 && $plans > $users * 30 && $plans <= $users * 100){
          $planVal = $('.pro .prices-currency.show').data('rate');

          $('.note, .pro, .enterprise').removeClass('d-none');
          $('.starter,.dasar, .basic').addClass('d-none');
        }else if($users > 1 && $plans > $users * 100 ){
          $planVal = $('.pro .prices-currency.show').data('rate');
          
          $('.enterprise').removeClass('d-none');
          $('.note, .pro,.dasar, .basic').addClass('d-none');
        }
        let $hours = ($users *  $industry) * 52;
        let $total = (($users *  $industry) * 52) * $rate;
        let $weeks = ($users * ($planVal*12))/ (($users *  $industry) * $rate);
        let $parsedWeeks = parseFloat($weeks).toFixed(1);
        
        $('.selected-industry').html($industry_val);
        $('.selected-industry-rate').html($industry_rate);

        let $calc_hours = '';
        if($currency == "€"){
          $calc_hours = ($users >= 20)? $hour_saving + $hours.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : $hours.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
          $('.results .calc-hours').html($calc_hours);
          $('.results .total').html($total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."));
        }else{
          $calc_hours = ($users >= 20)? $hour_saving + $hours.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : $hours.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          $('.results .calc-hours').html($calc_hours);
          $('.results .total').html($total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
        }
        
        $('.results .savings .currency').html($currency);
        $('.weeks').html($parsedWeeks);
        
        $('.results').addClass('show');
        $('html,body').animate({
          scrollTop: $(".results").offset().top - 105},
          'slow');
        return false;
      });
      
      $(document).on('change','.calculator',function(){
        $('.results').removeClass('show');
      });
    };
})(jQuery, app);