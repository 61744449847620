(function ($, app) {
    "use strict";

    app.m21 = function () {

        $('.m21-banner.clickable').on("click",function(){
            let $this = $(this);
            let $href = $this.attr('data-href');
            window.open($href, '_blank')
        });
        
    };
})(jQuery, app);