(function ($, app) {
    "use strict";
    app.m29v2 = function () {
        if($(".m29v2").length){
            if(window.location.hash) {
                var jobCollabse = $('[data-id="'+ window.location.hash.substring(1) +'"] button ').data('bs-target');
                    $(jobCollabse).collapse('show');
            }
            $('.jobs-filter-group ul li').on ('click', function() {
                $(this).parent().find('li').show();
                $(this).hide();
                // SET VALUE OF THE SELECTION ON THE BUTTON
                if (typeof $(this).data('city') !== 'undefined') {
                    $(this).parent().parent().find('button').text($(this).text()).attr('data-city', $(this).data('city'));
                } else if (typeof $(this).data('department') !== 'undefined') {
                    $(this).parent().parent().find('button').text($(this).text()).attr('data-department', $(this).data('department'))
                }
                // DEFIEN
                var city       = 
                                    // SET CITY NAME IF DATA-CITY IS HERE
                                    (typeof $(this).data('city') !== 'undefined') ? $(this).attr('data-city') : 
                                    // IF NOT GET IT FROM THE BUTTON
                                    $('.filter-cities button').attr('data-city');
                
                var department =    // SAME FOR DEPARTMENT
                                    (typeof $(this).data('department') !== 'undefined') ? $(this).attr("data-department") : 
                                    $('.filter-departments button').attr("data-department");           


                $('.job').show();
                if ( city != 'all') {
                    if (department == 'all') {
                        $('.job').not('[data-city='+ city +']').hide();
                    } else {
                        $('.job').not('[data-city='+ city +'][data-department='+ department +']').hide();
                    } 
                }
                if ( department != 'all') {
                    if (city == 'all') {
                        $('.job').not('[data-department='+ department +']').hide();
                    } else {
                        $('.job').not('[data-city='+ city +'][data-department='+ department +']').hide();
                    } 
                }
                if($('#openPositions').children(':visible').length == 0) {
                    $('.no-Jobs').removeClass('d-none');
                 } else {
                    $('.no-Jobs').addClass('d-none');
                 }
                
            });
            const player = new Plyr('.jobPostPlayer', {
                controls: [ 
                        'play-large',
                        'play', 
                        'progress', 
                        'current-time', 
                        'mute', 
                        'volume', 
                        'pip', 
                        'airplay', 
                        'fullscreen'
                    ],
            });
            $('.job').on('shown.bs.collapse', function () {
                $('html, body').animate({
                    scrollTop: $(this).offset().top
                }, 100);
                $(".apply-form").detach().appendTo($(this).find('.job-apply-container')).removeClass('d-none');
                $(".wp-job-block-row.wp-job-block-top").detach().appendTo($(this).find('.wp-job-top-blocks')).removeClass('d-none');
                $(".wp-job-block-row.wp-job-block-bottom").detach().appendTo($(this).find('.wp-job-bottom-blocks')).removeClass('d-none');
                $(this).find('.openApply').on('click', function() {
                    $(this).parent().parent().parent().parent().find('.job-apply-container').removeClass('d-none');
                });
                var video = $(this).data('video').replace('https://vimeo.com/', '');
                $(this).find('.wp-job-block-row iframe').attr('src', 'https://player.vimeo.com/video/'+ video.substr(0, 9) +'?loop=false&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=true&amp;transparent=1&amp;gesture=media')
                $('.job-apply-form form #jobid').val($(this).data('id'));
                

                player.source = {
                    type: 'video',
                    sources: [
                      {
                        src: video.substr(0, 9),
                        provider: 'vimeo',
                      },
                    ],
                  };
                  player.on('play', function(){
                    $('.wp-job-icon-video').hide();
                  });
            });
            $('.job').on('hidden.bs.collapse', function () {
                $(this).find('.job-apply-container').addClass('d-none');
                $(this).find('.job-success').addClass('d-none');
                $(this).find('.job-apply-form form').removeClass('invisible');
                $('.wp-job-icon-video').show();
            });
            //form
            $('.job-apply-form form').parsley({
                errorClass: 'is-invalid',
                successClass: 'parsley-success',
                errorsWrapper: '<div class="invalid-tooltip">',
                errorTemplate: '<span></span>'
            });
            // VALIDITING THE FILE EXT AND FILE SIZE
            $("#resume, #cover_letter").on('change', function(e){
                $(this).parsley().removeError('forcederror', {updateClass: true})
                var fileName = e.target.files[0].name;
                var allowedFiles = ['pdf', 'doc', 'docx', 'txt', 'rtf'];
                if ($.inArray(fileName.split('.').pop().toLowerCase(), allowedFiles) == -1 || e.target.files[0].size === 0) {
                    $(this).parsley().addError('forcederror', {message: 'empty file or unsupported file format only allowed (PDF, DOC, DOCX, TXT, RTF)', updateClass: true});
                }
            })
            // APPLY TO JOB
            $('.job-apply-form form').on("submit", function(e) {
                    e.preventDefault();
                    $(this).addClass('invisible');
                    $(this).parent().find('.job-loading').removeClass('d-none');
                    var dataRetention = false;
                    if ($('#dataRetention').is(":checked")) {
                        var dataRetention = true
                    }
                    $(this).parsley().whenValidate().done(function() {
                        let jobAPP = {
                            "first_name"            : $("#first_name").val(),
                            "last_name"             : $("#last_name").val(),
                            "email"                 : $("#email").val(),
                            "phone"                 : $("#phone").val(),
                            "resume"                : $("#resume")[0].files[0],
                            "cover_letter"          : $("#cover_letter")[0].files[0],
                            "question_5050806101"   : $("#linkedin").val(),
                            "question_5050807101"   : $("#website").val(),
                            "dataRetention"         : dataRetention,
                        }
                        var form_data = new FormData();
                        for ( var key in jobAPP ) {
                            form_data.append(key, jobAPP[key]);
                        }
                        var URL     = applyURL+ '?jobid=' +$("#jobid").val();
                        $.ajax({
                            type: 'POST',
                            url: URL,
                            data: form_data,
                            contentType: false,
                            processData: false,
                            success: function(){
                                $('.job-loading').addClass('d-none');
                                $('.job-success').removeClass('d-none');
                            },
                            fail: function(xhr, textStatus, errorThrown){
                                $('.JobApply').removeClass('invisible');
                                $('.job-loading').addClass('d-none');
                             }
                        })
                    })

            });
            // $('.job-apply-form form').on('submit', function(e) {
            //     e.preventDefault();
            //     var form = new FormData(this);

            //     console.log(this);


            //     // $(this).addClass('invisible');
            //     // $(this).parent().find('.job-loading').removeClass('d-none');
            //     // $(this).parsley().whenValidate().done(function() {
            //     //     var URL     = applyURL+ '?jobid=' +$("#jobid", this).val();
            //     //     let jobAPP = {
            //     //         "first_name"            : $("#first_name").val(),
            //     //         "last_name"             : $("#last_name").val(),
            //     //         "email"                 : $("#email").val(),
            //     //         "phone"                 : $("#phone").val(),
            //     //         "resume"                : $("#resume").prop('files'),
            //     //         "cover_letter"          : $("#cover").val(),
            //     //         "question_5050806101"   : $("#linkedin").val(),
            //     //         "question_5050807101"   : $("#website").val(),
            //     //     }
            //     //     $.post(URL, jobAPP).done(function() {
            //     //         $('.job-loading').addClass('d-none');
            //     //         $('.job-success').removeClass('d-none');
            //     //     }).fail(function() {
            //     //         $('.JobApply').removeClass('invisible');
            //     //         $('.job-loading').addClass('d-none');
            //     //     });
            //     // })
            // })
        }
      };
  })(jQuery, app);