(function ($, app) {
    "use strict";

    app.m78 = function () {
        if (document.querySelector('.m78') !== null) {
            $("#logoSearch").on("keyup", function() {
                var value = $(this).val().toLowerCase();
                console.log(value);
                $(".clientsLogo").filter(function() {
                    $(this).toggle($(this).data("filter").toLowerCase().indexOf(value) > -1);
                });
            });
        }
    };
})(jQuery, app);