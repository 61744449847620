
/* add class on scroll */

(function ($, app, _) {
    "use strict";

    app.AddClassScroll = function () {

        //caches a jQuery object containing the header element
        var header = $(".clearHeader");
        $(window).scroll(function() {
            var scroll = $(window).scrollTop();
            if(window.innerWidth > 1200){
                if (scroll >= 40) {
                    if($('.m79').height() > 0){
                        $('body').css('padding-top','54px');
                    }else{
                        $('body').css('padding-top','50px');
                    }
                    $('.header-menu').addClass("scrolled");
                } else {
                    if($('.m79').height() > 0){
                        $('body').css('padding-top','54px');
                    }else{
                        $('body').css('padding-top','0px');
                    }
                    $('.header-menu').removeClass("scrolled");
                }
                if($('.quick-guide').height() >0){
                    console.log('jkfj');
                    if (scroll >= $(window).height()*0.35) {
                        $('.sidebar').addClass("scrolled");
                    } else {
                        $('.sidebar').removeClass("scrolled");
                    }
                }
            }else{
                if (scroll > 0) {
                    $('.header-menu').addClass("scrolled");
                } else {
                    $('.header-menu').removeClass("scrolled");
                }
            }
        });
    };

})(jQuery, app, _);