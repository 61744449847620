(function ($, app) {
    "use strict";
  
    app.m17 = function () {
        $('.m17-slider').slick({
            dots: true,
            infinite: true,
            autoplay: false,
            autoplaySpeed: 2000,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            responsive: [
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 1,
                    // arrows: false,
                  }
                },
            ]
        });
      
    };
  })(jQuery, app);