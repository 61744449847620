(function ($, app) {
    "use strict";
    app.m40 = function () {
        $('.videoModal').on('show.bs.modal', function (e) {
            var videoURL = $(e.relatedTarget).data('video');
            if($(e.relatedTarget).hasClass('has-vimeo')){
                $('.videoModal .modal-body').html('<div class="m40-player plyr__video-embed" id="m40-player" style="--plyr-color-main:#015fbd;--plyr-video-background:#fff;"><iframe src="' + videoURL + '?autoplay=1&amp;loop=false&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=true&amp;transparent=1&amp;gesture=media"  allow="autoplay" allowfullscreen allowtransparency></iframe></div>');
                $(e.relatedTarget).next('button[class="^Button_module_button"]').click();
                const m40player = new Plyr('#m40-player',{
                    autoplay: true,
                    muted: true,
                    playsinline: true,
                    controls: [ 
                        'play', 
                        'progress', 
                        'current-time', 
                        'mute', 
                        'volume', 
                        'pip', 
                        'airplay', 
                        'fullscreen'
                    ]
                });
                m40player.play();
            }else{
                $('.videoModal .modal-body').html('<div class="embed-responsive embed-responsive-16by9"><video class="embed-responsive-item" controls autoplay><source id="clientVideoModal_play" src="' + videoURL + '" type="video/mp4">Your browser does not support the video tag.</video></div>');
            }
          });
          $('.videoModal').on('hidden.bs.modal', function () {
             $('.videoModal .modal-body').empty();
          });
    };
})(jQuery, app);