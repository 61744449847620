(function ($, app) {
    "use strict";

    app.m102 = function () {
        
        if($(".m102").length){
            $('.download-box').on('click', function() {
                $('.download-box').not(this).removeClass('opened')
                $(this).toggleClass('opened')
            });

            $('.copy-url').click(function (e) {
                e.preventDefault();
                var URL = $(this).attr('href');
                document.addEventListener('copy', function(e) {
                   e.clipboardData.setData('text/plain', URL);
                   e.preventDefault();
                }, true);
                document.execCommand('copy');  
                $(this).find(".msg").show().delay(1000).fadeOut();
              });
        }
    };
})(jQuery, app);