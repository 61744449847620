(function ($, app) {
    $(".city-contact-info").on("click",function(){
        $(".city-contact-info-box").hide();
        $(this).find(".city-contact-info-box").show();
    });
    $(".close_office_details").on("click",function(e){
        e.preventDefault();
        $(this).parent().hide();
        return false;
      });
      $("p.international-contacts_country").on("click",function(){
        $(".international-contacts_list ul").not($(this).next()).removeClass('show-cities');
        $("p.international-contacts_country").not($(this)).removeClass('show-country');
        $(this).next().toggleClass('show-cities');
        $(this).toggleClass('show-country');
    });      
})(jQuery, app);