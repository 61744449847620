(function ($, app) {
    "use strict";

    app.m57 = function () {
        $('.customers-container-logo.with-description').on('click', function() {
            let boxPosition = $(this).offset();
            //console.log('boxPosition',boxPosition);
            let boxWidth = $(this).outerWidth();
            //console.log('boxWidth',boxWidth);
            let boxRight = boxPosition.left + boxWidth;
            //console.log('boxRight',boxRight);
            let customers = $('.customers-row').offset();
            //console.log('customers',customers);
            let customersWidth = $('.customers-row').outerWidth();
            //console.log('customersWidth',customersWidth);
            let customersRight = customers.left + customersWidth;
            //console.log('customersRight',customersRight);
            if(customersRight - boxRight <= '20'){
                $(this).css({right: 0});
                //console.log('Right',customersRight - boxRight)
            }
            $('.customers-container-logo.with-description').not(this).removeClass('description-opened')
            $('.logo_description').not($(this).find('.logo_description')).addClass('d-none');
            $(this).toggleClass('description-opened');
            $(this).find('.logo_description').toggleClass('d-none');
        });

        if (document.querySelector('.customers-row') !== null) {
            // INITIALIZING ISOTOPE
            var $grid = $('.grid').isotope({
                // options
                itemSelector: '.grid-item',
                layoutMode: 'masonry',
                fitRows: {
                    gutter: 0
                  },
                percentPosition: true,
                masonry: {
                  columnWidth: '.grid-sizer'
                },
                getSortData: sortData,
            });
            // FILTER VIA BUTTON
            var $buttonGroup = $('.filters');
            $buttonGroup.on( 'click', 'button', function( event ) {
                $buttonGroup.find('.is-checked').removeClass('is-checked');
                var $button = $( event.currentTarget );
                $button.addClass('is-checked');
                var filterValue = $button.attr('data-filter');
                var dsktpselected = filterValue.substring(1, filterValue.length);
                $grid.isotope({
                    filter: filterValue,
                    sortBy: dsktpselected,
                    sortAscending: true
                });
                $grid.isotope('layout');
            });

            // FILTER VIA SELECT
            var $select = $('.filters-select');
            $select.on( 'change', function( event ) {
                var filterValue = $select.val();
                var dsktpselected = filterValue.substring(1, filterValue.length);
                $grid.isotope({
                    filter: filterValue,
                    sortBy: dsktpselected,
                    sortAscending: true
                });
                $grid.isotope('layout');
            });

            // WHEN DOCUMENT READY CLICK FEATURED
            $("document").ready(function() {
                $('button[data-filter=".featured"]').trigger('click').addClass('is-checked');
                $('.cs-top-filter').each(function(){
                    $(this).addClass('ready');
                })
            });

            // HIDE ALL BUTTON WHEN CLICKED
            $('button[data-filter=".all"]').on("click", function() {
                $(this).addClass('d-none');
                $('.section-m57').removeClass('rows-6');
                 
            });

            // SHOW ALL BUTTON WHEN FEATURED CLICK
            $('button[data-filter=".featured"]').on("click", function() {
                $('button[data-filter=".all"]').removeClass('d-none');
            });
        }
    };
})(jQuery, app); 