(function ($, app) {
    "use strict";

    app.single = function () {
        if($('.blog-nav').length){ 
            $('.m90-blog article h2').each(function($i) {
                let $h2 = $(this);
                let $h2ID = ($($h2).attr('id'))? $($h2).attr('id') :  'article-body-h2'+$i;
                if($h2.parent().hasClass('tabcontent') && $h2.parent().hasClass('active')){
                    
                    $h2.attr('id', $h2ID);
                    let $h3s = '';
                    $h2.nextUntil('h2').filter('h3').each(function($j) {
                        let $h3ID = ($(this).attr('id'))? $(this).attr('id') : $h2ID+'-h3'+$j;
                        $(this).attr('id',$h3ID);
                        $h3s += '<li class="'+$h3ID+'"><a href="#'+$h3ID+'">'+$(this).text()+'</a></li>';
                    });
                    if($i==0){
                        $('.blog-nav>ul').append('<li class="'+$h2ID+'"><a class="active" href="#'+$h2ID+'">'+$h2.text()+'</a><ul>'+$h3s+'</ul></li>');
                    }else{
                        $('.blog-nav>ul').append('<li class="'+$h2ID+'"><a href="#'+$h2ID+'">'+$h2.text()+'</a><ul>'+$h3s+'</ul></li>');
                    } 
                }else if($h2.parent().hasClass('tabcontent')){
                }else{
                    $h2.attr('id', $h2ID);
                    let $h3s = '';
                    $h2.nextUntil('h2').filter('h3').each(function($j) {
                        let $h3ID = ($(this).attr('id'))? $(this).attr('id') : $h2ID+'-h3'+$j;
                        $(this).attr('id',$h3ID);
                        $h3s += '<li class="'+$h3ID+'"><a href="#'+$h3ID+'">'+$(this).text()+'</a></li>';
                    });
                    if($i==0){
                        $('.blog-nav>ul').append('<li class="'+$h2ID+'"><a class="active" href="#'+$h2ID+'">'+$h2.text()+'</a><ul>'+$h3s+'</ul></li>');
                    }else{
                        $('.blog-nav>ul').append('<li class="'+$h2ID+'"><a href="#'+$h2ID+'">'+$h2.text()+'</a><ul>'+$h3s+'</ul></li>');
                    } 
                }
            });
            $('.blog-nav').show(500);
        }else{
            $('.article-sidebar').hide();
            $('.blog-nav').hide(500);
        }
        $('.blog-nav ul li a').on('click',function(){
            $('.blog-nav ul li a.active').removeClass('active');
            $(this).addClass('active');
    
            $('html, body').animate({
                scrollTop: $($.attr(this, 'href')).offset().top - 100
            }, 500);
            window.location.replace($.attr(this, 'href'));
            return false;
        });
        
    };
})(jQuery, app);
