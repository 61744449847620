(function ($, app) {
    "use strict";

    app.m76 = function () {

        $(document).on('click','.nav-tabs-arrow,.nav-tabs',function(){
            if(window.innerWidth < 767){
                $('.nav-tabs-arrow').addClass('active');
                $('.nav-tabs').addClass('active');
                $('.tab-content').addClass('dd-expanded');
            }
        });
        $(document).on('click','.nav-tabs-arrow.active,.nav-tabs.active',function(){
            if(window.innerWidth < 767){
                $('.nav-tabs-arrow').removeClass('active');
                $('.tab-content').removeClass('dd-expanded');
                $('.nav-tabs').removeClass('active');
            }
        });
        $(document).on('click','.nav-link',function(){
            $('.nav-tabs-arrow').removeClass('active');
            $('.tab-content').removeClass('dd-expanded');
            $('.nav-tabs').removeClass('active');
        });
    };
})(jQuery, app);