(function ($, app) {
  "use strict";
  app.m29 = function () {
    $('.jobs_filter').change(function() {
        // Initialize criteria string
        var criteria = '';
        // Set value for all selector
        var showAll = true;
  
        // Iterate over all criteriaSelectors
        $('.jobs_filter').each(function(){
          // Get value
          var val = $(this).children(':selected').val();
          // Check if this limits our results
          if(val !== 'all'){
            // Append selector to criteria
            criteria += '.' + $(this).attr('id') + '-' + val;
            // We don't want to show all results anymore
            showAll = false;
          }
        });
        // Check if results are limited somehow
        if(showAll){
          // No criterias were set so show all
          $('.jobPost-item').show();
        } else {
          // Hide all items
          $('.jobPost-item').hide();
          // Show the ones that were selected
          $(criteria).show();
        }
  
      });
    };
})(jQuery, app);