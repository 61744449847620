(function ($, app) {
    "use strict";

    app.m64 = function () {
        function filter_apps(search, href){
            console.log('href',href)
            if(search.length>0){
              var url = new URL(href);
              url.searchParams.set('search', search);
              $('.contact-btn').attr('href',url);
              $('.app').each(function(){
                $(this).attr('href',url);
                if ($(this).data('title').toLowerCase().indexOf(search.toLowerCase()) == -1){
                  $(this).hide();
                }else{
                  $(this).show();
                }
              });
            }else{
              $('.app').slice(0,21).each(function(){
                $(this).show();
              });
              $('.app').slice(21).each(function(){
                $(this).hide();
              });
            }
            if($('.apps').children(':visible').length == 0) {
              $('.show-more').hide();
              $('.contact-specialist').show();
            }else if($('.apps').children(':visible').length > 0 && $('.apps').children(':visible').length < 21){
              $('.show-more').hide();
              $('.contact-specialist').hide();
            }else{
              $('.show-more').show();
              $('.contact-specialist').hide();
            }
        }
          $(document).on('keyup','.search-apps',function(){
            let search = $(this).val();
            let href = $(this).data('href');
            filter_apps(search,href);
          });  
          $(document).on('search','.search-apps',function(){
            let search = $(this).val();
            let href = $(this).data('href');
            filter_apps(search,href);
          });
          $(document).on('click','.show-more',function(){
            $(this).hide();
            $('.app').slice(20).each(function(){
              $(this).show();
            });
          });
    };
})(jQuery, app);