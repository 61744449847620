(function ($, app) {
    "use strict";

    app.m68 = function () {
        $('.VideoPlayIcon').on("click",function(){
            $('video').trigger('pause');
            $(this).next().trigger('play');
            $(this).next().attr('controls',true);
            $(this).addClass('d-none');
          });
          // PAUSE VIDEO ON WINDOW RESIZE
          $(window).on('resize', function(){
            $('.m68 video').trigger('pause');
        });
    };
})(jQuery, app); 