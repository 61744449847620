(function ($, app) {
    "use strict";

    app.m80 = function () {
        if($(".hs-form-container").length){
        $(document).ready(function(){
            window.addEventListener('message', event => {
                 if(event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmitted') {
                    $('.add-more-contacts').removeClass('d-none');
                    $('.add-more-contacts button').on('click', function() {
                        hbspt.forms.create({
                            region: "na1",
                            portalId: "7061715",
                            formId: event.data.id,
                            target: ".hs-form-container" // Add the element class where you need to create the form again
                            });
                        $('.add-more-contacts').addClass('d-none');
                        $('.hs-reload-loading').removeClass('d-none');
                        $('.submitted-message').hide();
                    })
                 }
                 if(event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormReady') {
                    $('.hs-reload-loading').addClass('d-none');
                 }
                 
              });
            
          });
        }
    };
})(jQuery, app);