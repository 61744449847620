(function ($, app, _) {
    "use strict";

    app.SlickSliderSetup = function () {
        $('.slider-for').slick({
            infinite: false,
            arrows: true,
            dots: false,
            autoplay: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            fade: true,
            asNavFor: '.slider-nav',
            prevArrow: $('.prev'),
            nextArrow: $('.next'),
            responsive: [
                {
                    breakpoint: 992,
                    infinite: false,
                    arrows: false,
                    dots: false,
                }
            ]
        });
        $('.slider-nav').slick({
            infinite: false,
            slidesToShow: 5,
            slidesToScroll: 1,
            asNavFor: '.slider-for',
            dots: false,
            focusOnSelect: true,
            responsive: [
                {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    breakpoint: 992,
                    dots: true
                }
            ]
        });


        var $sliders = $(".artists-slider");
        var $arrows = $('.arrows');

        $(".m08").each(function () {

            var $this = $(this);
            var slick = $this.find($sliders).slick({
                appendArrows: $this.find($arrows),
                infinite: false,
                arrows: true,
                dots: false,
                swipeToSlide: true,
                autoplay: false,
                slidesToShow: 3,
                slidesToScroll: 3,
                responsive: [
                    {
                        breakpoint: 991,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                        }
                    },
                    {
                        breakpoint: 320,
                        settings: {
                            slidesToShow: 1
                        }
                    }
                ]
            });

        });


        var $slidersat = $(".quote-slider");
        var $arrowsat = $('.arrows');

        $(".m04").each(function () {

            var $this = $(this);
            var slick = $this.find($slidersat).slick({
                appendArrows: $this.find($arrowsat),
                infinite: true,
                arrows: true,
                dots: false,
                autoplay: false,
                slidesToShow: 1,
                slidesToScroll: 1
            });

        });

        $('.home-slider').slick({
            infinite: true,
            arrows: true,
            dots: false,
            autoplay: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            fade: true,
            asNavFor: '.slider-nav-m01',
            prevArrow: $('.m01 .slick-prev'),
            nextArrow: $('.m01 .slick-next'),
            responsive: [
                {
                    breakpoint: 992,
                    infinite: false,
                    arrows: false,
                    dots: false,
                }
            ]
        });
        $('.slider-nav-m01').slick({
            infinite: false,
            slidesToShow: 3,
            vertical: true,
            slidesToScroll: 3,
            asNavFor: '.home-slider',
            dots: false,
            focusOnSelect: true,
        });



        $('a[data-slide]').click(function(e) {
            e.preventDefault();
            var slideno = $(this).data('slide');
            $('.home-slider').slick('slickGoTo', slideno - 1);
        });



        var $sliderbox = $(".three-boxes-slider");
        var $arrowsbox = $('.arrows');

        $(".m02").each(function () {

            var $this = $(this);
            var slick = $this.find($sliderbox).slick({
                appendArrows: $this.find($arrowsbox),
                infinite: false,
                arrows: true,
                dots: false,
                autoplay: false,
                slidesToShow: 3,
                slidesToScroll: 1,
                responsive: [
                    {
                        breakpoint: 991,
                        settings: {
                            slidesToShow: 2
                        }
                    },
                    {
                        breakpoint: 320,
                        settings: {
                            slidesToShow: 1
                        }
                    }
                ]
            });

        });

        var $sliderpackage = $(".packages-slider");
        var $arrowspackage = $('.arrows');

        $(".m05").each(function () {

            var $this = $(this);
            var slick = $this.find($sliderpackage).slick({
                appendArrows: $this.find($arrowspackage),
                infinite: false,
                arrows: true,
                dots: false,
                autoplay: false,
                slidesToShow: 3,
                slidesToScroll: 1,
                responsive: [
                    {
                        breakpoint: 991,
                        settings: {
                            slidesToShow: 2
                        }
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 1
                        }
                    }
                ]
            });

        });

        $('.m10-testimonial').slick({
            slidesToShow: 3,
            slidesToScroll: 3,
            dots: false,
            arrows: false,
            infinite: true,
            speed: 2500,
            autoplay: true,
            autoplaySpeed: 2000,
            responsive: [
               {
                 breakpoint: 768,
                 settings: {
                 slidesToShow: 1,
                 slidesToScroll: 1,
                 }
               },
               {
                 breakpoint: 992,
                 settings: {
                 slidesToShow: 2,
                 slidesToScroll: 2,
                 }
               },
             ]
        });
        $('.m37-slider').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            arrows: true,
          });

        $('.m74-slider:not(.only-child)').each(function(){
            if($('html')[0].dir == 'rtl'){
                $(this).slick({
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: true,
                    rtl: true,
                    prevArrow: $(this).parent().find('.arrow-left'),
                    nextArrow: $(this).parent().find('.arrow-right'),
                    autoplay: true,
                    autoplaySpeed: 6000,
                    responsive: [
                        {
                            breakpoint: 991,
                            settings: {
                                arrows: false,
                            }
                        }
                    ]
                });
            } else {
                $(this).slick({
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: true,
                    prevArrow: $(this).parent().find('.arrow-left'),
                    nextArrow: $(this).parent().find('.arrow-right'),
                    autoplay: true,
                    autoplaySpeed: 6000,
                    responsive: [
                        {
                            breakpoint: 991,
                            settings: {
                                arrows: false,
                            }
                        }
                    ]
                });
            }

        });
        if($(".csVideosCarousel").length){
            $('.csVideosCarousel').on('slide.bs.carousel', function () {
                pauseVimeo();
            });
            function plyrStat() {
                sliderPlayers.forEach(function(player) {
                    player.on('playing play', function() {
                        $('.csVideosCarousel').addClass('playing');
                    });
                    player.on('pause ended', function() {
                        $('.csVideosCarousel').removeClass('playing');
                    });
    
                });
            }
            plyrStat();
        };



        // CUSTOMER STORIES MORE SLIDER
        $('.cs-more-slider').slick({
            infinite: true,
            arrows: true,
            dots: true,
            autoplay: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 992,
                    settings : {
                        arrows: false,
                    }
                }
            ]
        });
        $('.cs-solutions-slider').slick({
            infinite: false,
            arrows: false,
            dots: false,
            autoplay: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 992,
                    settings : {
                        slidesToShow: 1,
                        dots: true,
                        infinite: true,
                    }
                }
            ]
        });

        // M85 SLIDER
        $('.m85-icons-slider').slick({
            infinite: false,
            arrows: false,
            dots: false,
            autoplay: false,
            slidesToShow: 6,
            slidesToScroll: 1,
            variableWidth: true,
            // asNavFor: '.slider-nav-m01',
            // prevArrow: $('.m01 .slick-prev'),
            // nextArrow: $('.m01 .slick-next'),
            responsive: [
                {
                    breakpoint: 992,
                    settings : {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    }
                }
            ]
        });
        // REF https://github.com/kenwheeler/slick/issues/2123#issuecomment-1140008876
        // CHECKING .M99-STEPS
        if($(".m99").length){
            let stepsSlider = $('.m99-steps');
            const slickSettings = {
                infinite: false,
                dots: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                mobileFirst: true,
                arrows:false,
                responsive: [
                    {
                        breakpoint: 992,
                        settings : 'unslick'
                    }
                ]
            }
            const m99steps = $(stepsSlider).slick(slickSettings)
            // CHECK IF WINDOW RESIZED
            $(window).on('resize', function() {
                if( $(window).width() < 992 &&  !m99steps.hasClass('slick-initialized')) {
                    $(stepsSlider).slick(slickSettings);
                }
            })
        }
        // LOGO SCROLL ON MOBILE
        if($(".m02.scroll").length){
            $('.m02.scroll .logos').slick({
                infinite: true,
                arrows: true,
                autoplay: true,
                slidesToShow: 4,
                slidesToScroll: 1,
                mobileFirst: true,
                responsive: [
                    {
                        breakpoint: 992,
                        settings : 'unslick'
                    }
                ]
            });
        }

    };

})(jQuery, app, _);