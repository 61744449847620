(function ($, app) {
    "use strict";

    app.m18 = function () {

        // $(document).on('click','.accordion .question',function() {
        //     let $this = $(this);
        //     let $target = $this.data('target');
        //     if($($target).hasClass('show')){
        //         $($target).removeClass('show');
        //     }else{
        //         $('.accordion .answer.show').each(function(){
        //             $(this).removeClass('show');
        //         });
        //         $('.accordion .question.expanded').each(function(){
        //             $(this).removeClass('expanded');
        //         });
        //         // $($target).addClass('collapsing');
        //         // $($target).removeClass('collapsing');
        //         $($target).toggleClass('show');
        //     }
        //     $this.toggleClass('expanded');
        // });
    };
})(jQuery, app);