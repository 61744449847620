(function ($, app) {
    "use strict";

    app.header = function () {

      //* Main Menu - border bottom on hover
      $('.menu[id^="menu-main-menu"] .menu-item-has-children').on('mouseenter',function() {
          $(".main-menu").addClass("hovered");
      }).on('mouseleave',function() {
        $(".main-menu").removeClass("hovered");
      });

      //* Language Switcher
      $('.lang-switcher:not(.mobile)').on('mouseenter',function() {
        if(window.innerWidth > 1200){
          let position = $(this).offset();
          let width = $(this).outerWidth();
          let right = window.innerWidth - position.left - width;
          //if(!$(".topmenu_countries").hasClass('mobile-countries')){
            if($('html')[0].dir == 'rtl'){
              right = position.left;
              $(".topmenu_countries").css({right: 'auto'});
              $(".topmenu_countries").css({left: right});
            }else{
              right = window.innerWidth - position.left - width;
              $(".topmenu_countries").css({right: right});
            }
            $(".topmenu_countries").css({top: position.top});
            $('.topmenu_countries').removeClass('invisible');
          //}
        }
      });
      $(document).on('click','.lang-switcher',function() {
          // let top = $('.mobile-nav').position().top;
          $('.overlay.overlay-mobile').addClass('show');
          // $(".topmenu_countries").css({top: top});
          $('.topmenu_countries').removeClass('invisible');
          $('body').addClass('popup_opened');
          if(window.innerWidth < 1200){
            let top = window.pageYOffset + 70;
            $(".topmenu_countries").css({top: top});
          }
      });
      $('.topmenu_countries').on('mouseleave',function() {
        if(window.innerWidth > 1200){
          $('.topmenu_countries').addClass('invisible');
        }
      });
      $(document).on('click',".icon-closen",function(){
          $('.topmenu_countries').addClass('invisible');
          $('.overlay-mobile').removeClass('show');
          $('body').removeClass('popup_opened');
      });

      //* Menu Button
      $(document).on('click','.menu-btn',function(){
        $('.mobile-menu').addClass('show');
        $('.signup-btn').removeClass('d-md-block');
        $('.overlay:not(.overlay-mobile)').addClass('show');
        $('body').addClass('unscrollable');
      });
      $(document).on('click','.close-menu',function(){
        $('.mobile-menu').removeClass('show');
        $('.signup-btn').addClass('d-md-block');
        $('.overlay:not(.overlay-mobile)').removeClass('show');
        $('body').removeClass('unscrollable');
      });

      //* Mobile Nav Item
      $('#menu-mobile-main-menu > .menu-item-has-children > a, #menu-main-menu-1 > .menu-item-has-children > a,.mobile-main-menu .menu > .menu-item-has-children > a, #menu-supporting-menu-1 > .menu-item-has-children > a').on('click',function(e){
        e.preventDefault();
        if($('.mobile-main-menu').hasClass('slide-left')){
          $('.menu-item-has-children.expanded').removeClass('expanded');
            $('.mobile-main-menu').removeClass('slide-left');
            $('.mobile-nav .btns').removeClass('slide-left');
        }else{
          let $this = $(this).parent('.menu-item-has-children');
          if($this.hasClass('expanded')){
            $this.removeClass('expanded');
            $('.mobile-main-menu').removeClass('slide-left');
            $('.mobile-nav .btns').removeClass('slide-left');
          }else{
            $('.menu-item-has-children.expanded').each(function(){
              console.log('else');
              $(this).removeClass('expanded');
            });
            $this.addClass('expanded');
            $('.mobile-main-menu').addClass('slide-left');
            $('.mobile-nav .btns').addClass('slide-left');
          }
        }
      });


      // Cookie functions from w3schools
      function setLangCookie(lname, lvalue, lexdays) {
        var d = new Date();
        d.setTime(d.getTime() + (lexdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = lname + "=" + lvalue + ";" + expires + ";path=/;SameSite=Lax";
      }
      //SET COUNTRY COOKIE
      var vCountry = $('.fswitch_country');
      vCountry.on( "click", function() { 
        var cookieVal = $(this).attr('id');
          setLangCookie("temporary_switch", cookieVal, 365);
      });
      
      if ($('.regions-container').length) {
        $('.lang-switcher.mobile').on('click',function() {
          $('.regions-container').css({'display': 'block',});
        });

        $('.lang-switcher:not(.mobile)').on('mouseenter',function() {
          var position = $(this).offset();
          var width = $(this).outerWidth();
          var left = position['left'] + width - 350;
          $('.regions-container:not(:lang(ar))').css({'display': 'block', 'top': position['top'], 'left': left+ 'px'});
          $('.regions-container:lang(ar)').css({'display': 'block', 'top': position['top'], 'left': position['left']+ 'px'});
        });
        $('.regions-container').on('mouseleave',function() {
          $(this).css({'display': 'none'});
        })


        $('.icon-closen').on('click', function() {
          $('.regions-container').css({'display': 'none'});
        });

        $(document).mouseup(function(e){
            var regionsMenu = $(".regions");
            if (!regionsMenu.is(e.target) && regionsMenu.has(e.target).length === 0) {
              $('.regions-container').css({'display': 'none'});
            }
        });
        
        $('.regions > ul > li').on('click',function() {
          $('.regions > ul > li').not(this).removeClass('opened');
          $(this).toggleClass('opened');
          // $(this).children('ul').toggleClass('opened');
        })

        $('.cancel-country-search').on('click', function() {
          $("#search-countries").val('');
          $('.regions ul:not(.search-countries)').removeClass('d-none');
          $('.regions ul.search-countries').addClass('d-none');
          $('.regions .search').removeClass('active');
        });

        $("#search-countries").on("keyup", function() {
            var value = $(this).val().toLowerCase();
            if(value.length === 0) {
              $('.regions ul:not(.search-countries)').removeClass('d-none');
              $('.regions ul.search-countries').addClass('d-none');
              $('.regions .search').removeClass('active');
            } else {
              $('.regions ul:not(.search-countries)').addClass('d-none');
              $('.regions ul.search-countries').removeClass('d-none');
              $('.regions .search').addClass('active');
            }
            $(".regions ul.search-countries > li:not(.no-result-msg)").show().filter(function() { 
              return $(this).find('a').text().toLowerCase().indexOf(value) === -1;
            }).hide();
            if($('.regions ul.search-countries > li:not(.no-result-msg) a:visible').length===0){
              $('.regions p.error').removeClass('d-none');
            } else {
              $('.regions p.error').addClass('d-none');
            }
        });
        function closeAllmenus () {
          $('.regions-container').css({'display': 'none'});
          $('.mobile-menu').removeClass('show');
          $('.signup-btn').addClass('d-md-block');
          $('.overlay:not(.overlay-mobile)').removeClass('show');
          $('body').removeClass('unscrollable');
        }
        $('a.fswitch_country, .mobile-nav li:not(.menu-item-has-children)').on('click', function() {
          closeAllmenus();
        });
      }
    };
})(jQuery, app);