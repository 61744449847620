(function ($, app) {
    "use strict";

    app.m107 = function () {
        if($(".m107").length){
            // CHECKING WHILE FILLING IF THE INPUT IS NUMBER
            $("#area").on("keyup change",  function(e) {
                if (/\d+|,+|[/b]+|-+/i.test(e.key) ){
                    $('.area').removeClass('error');
                    $('button.calculate').removeClass('btn disabled');
                } else {
                    $('.area').addClass('error');
                    $('button.calculate').addClass('btn disabled');
                }                
                $(this).text($(this).text().toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1" +separator));
            });
            $("#area").bind('keypress', function(e) {
                if (e.keyCode == 13) {
                    e.preventDefault();
                }
            });
            $('.calculate').on('click', function(e) {
                e.preventDefault();
                $('.area-errors, .area-errors li').addClass('d-none');
                var area = $('#area').text().replaceAll(separator, ''),
                    period  = $('.period input:checked').val(),
                    ratio   = 0.7,
                    tpm     = 0.000222222,
                    hours   = area * ratio * tpm * period,
                    answer  = Math.round(hours).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1" +separator);
                    $('.estimation span').text(answer);
                    $('html, body').animate({
                        scrollTop: $('.result').offset().top + (-200)
                    }, 500);
                


                


            })
        }






    };
})(jQuery, app);