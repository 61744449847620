(function ($, app) {
    "use strict";

    app.m101 = function () {
        
        if($(".m101").length){
            $('.content-page a').not('.m01new2 a').on('click', function(e) {
                if (!sessionStorage['form']) {
                    e.preventDefault();
                    $('#hsFormLP').modal('show');
                    window.url = $(this).attr('href');
                }
            })
            $('.plyr__control.plyr__control--overlaid').on('click',function(e) {
                if (!sessionStorage['form']) {
                    players.forEach(function(player) {
                        player.pause();
                    });
                    $('#hsFormLP').modal('show');
                }
            })
            // WHEN FORM SUBMITTED
            $(document).ready(function(){
                window.addEventListener('message', event => {
                    if(event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmitted') {
                        sessionStorage.setItem('form', 1);
                        $('#hsFormLP').modal('hide');
                        if (typeof url === 'undefined') {
                            players.forEach(function(player) {
                                player.play();
                            });
                        } else {
                            window.open(
                                url,
                                '_blank'
                            )
                        }
                    }
                })
            });

        }
    };
})(jQuery, app);