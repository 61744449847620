(function ($, app) {
    "use strict";

    app.m50 = function () {
        
        $('.box-slider').slick({
            slidesToShow: 3,
            slidesToScroll: 3,
            dots: true,
            infinite: true,
            speed: 1500,
            autoplay: true,
            autoplaySpeed: 3500,
            arrows: true,
            responsive: [
                {
                  breakpoint: 768,
                  settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  arrows: false,
                  }
                },
                {
                  breakpoint: 991.98,
                  settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  }
                },
            ]
        });
    };
})(jQuery, app);