(function ($, app) {
    "use strict";

    app.m31 = function () {
        $(document).on('click','.col-team-member',function(){
            if(window.innerWidth > 767){
                let parent = $(this).offsetParent();
                let position = $(this).offset();
                let top = position.top - parent.top;
                $(".team-member-info").css({top: top});
            }else{
                $('body').addClass('popup_opened');
            }
            $('.team-member-info:not(.d-none)').addClass('d-none');
            $(this).next('.team-member-info').removeClass('d-none');
            // let leadershipPopup = $(this).position().top;
            // let leadersRow      = $('.team').height();
            // let leaderBoxH3     = ($(this).height()+15)*3;
            // let leaderBoxPlace  = leadersRow - leadershipPopup


            // console.log(leadersRow - leadershipPopup);

            // if(leaderBoxPlace <= leaderBoxH3 ) {
            // $(this).next('.team-member-info').css({"bottom": "15px"});
            // } else {
            // $(this).next('.team-member-info').css({"top": leadershipPopup});
            // }
        });
        $(document).on('click','.box_close',function(){
            $(this).closest('.team-member-info').addClass('d-none');
            $('body').removeClass('popup_opened');
        });
    };
})(jQuery, app);