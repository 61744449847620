/* constructor */

var app = {};
window.app = app || {};

var _mfq = _mfq || [];

(function ($, app, _) {
  _.extend(app, {
    appBaseUrl: app_js_base,

    $body: $('body')

  });
})(jQuery, app, _);
