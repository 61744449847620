/* responsive module */

(function ($, app) {
    "use strict";

    app.m01 = function () {
          // DETECTING OS
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (/android/i.test(userAgent)) {
            $('.all_apps').addClass('d-none');
            $('.android_app').removeClass('d-none');
        } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream || navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 0) {
            $('.all_apps').addClass('d-none');
            $('.ios_app').removeClass('d-none');
        }
        $(window).on('scroll',function() {
            if($(document).scrollTop() > 700 && window.innerWidth < 767){
                $(".hero:not(.innerpage) .cta_btn").removeClass("xs-easy-out");
                $(".hero:not(.innerpage) .cta_btn").addClass("xs-easy-in");
                $(".hero:not(.innerpage) .cta_btn").addClass('fixed');
            } else {
                if($(".hero:not(.innerpage) .cta_btn").hasClass('fixed')){
                    $(".hero:not(.innerpage) .cta_btn").addClass("xs-easy-out");
                    $(".hero:not(.innerpage) .cta_btn").removeClass("xs-easy-in");
                    setTimeout(function() { 
                        $(".hero:not(.innerpage) .cta_btn").removeClass("xs-easy-out");
                        $(".hero:not(.innerpage) .cta_btn").removeClass('fixed');
                    },500);
                }
            }
        });
        $('.mockup-slider').slick({
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            arrows: false,
            fade: true,
            settings: {
                
            }
        });
    };
})(jQuery, app);