(function ($, app) {
    "use strict";

    app.m85 = function () {
        if($(".vt-player").length){
            var video = $(".vt-player").get(0);      
            video.addEventListener("loadedmetadata", videoData);
            if (video.readyState >= 2) {
                videoData();
            }
            function videoData() {
                var videoEnd = video.duration;
                $( ".vt-title:last-of-type" ).attr('data-end', videoEnd);
                $('.vt-title').each(function(){
                    var totalTime = $(this).data('end') - $(this).data('start');
                    $(this).get(0).style.setProperty('--duration',totalTime+'s');
                })
                $(".vt-player").on('pause', function(e) {
                    $(".vt-title").removeClass("vt-play");
                })
                $(".vt-player").on('play', function(e) {
                    $(".vt-title").addClass("vt-play");
                })
            }
        }

        function titleNow() {            
            $('.vt-title').each(function(){
                if($(this).data('start') < video.currentTime) {
                    $('.vt-title').removeClass('vt-active')
                    $('.vt-title').find('.vt-title-bar').removeClass('vt-move');
                    $(this).addClass('vt-active');
                    $(this).find('.vt-title-bar').addClass('vt-move');
                }
            })
        }
        setInterval(titleNow, 1000);
    };
})(jQuery, app);