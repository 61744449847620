(function ($, app) {
    "use strict";

    app.m81 = function () {
        
        $(document).on('click','.map-show-more',function(){
            let $target = $(this).data('id');
            console.log('$target',$target);
            if(!$('#'+$target+' .gallery').hasClass('slick-initialized')){
                $('#'+$target+' .gallery').slick({
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                    centerMode: true,
                    centerPadding: '10%',
                    arrows: true,
                    prevArrow: $('#'+$target+' .arrow-left'),
                    nextArrow: $('#'+$target+' .arrow-right'),
                    responsive: [
                        {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            breakpoint: 992,
                        }
                    ]
                });
            }
            $('#'+$target).addClass('show');
            return false;
        });
        $(document).on('click','.close-office-gallery',function(){
            $('.office-gallery.show').removeClass('show');
            $('.office.active').removeClass('active');
            $('.gm-ui-hover-effect').click();
            return false;
        });
    };
})(jQuery, app);