(function ($, app) {
    "use strict";

    app.m91 = function () {
        if (document.querySelector('.m91') !== null) {
            function getCookie(name) {
                var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
                if (match) return match[2];
                else return 'undefined';
            }

            $('.blog-newsletter form').parsley({
                errorClass: 'is-invalid',
                successClass: 'parsley-success',
                errorsWrapper: '<span class="text-help">',
                errorTemplate: '<div></div>'
            });

            $(".blog-newsletter form").on("submit", function(e){
                e.preventDefault();
                $('.form-loaded').addClass('d-none');
                $('.form-loading').removeClass('d-none');
                var hsFormID = $(this).attr("data-hs");
                var lt_medium   = getCookie("pr_lt_utm_medium");
                var lt_source   = getCookie("pr_lt_utm_source");
                var lt_campaign = getCookie("pr_lt_utm_campaign");
                var lt_content  = getCookie("pr_lt_utm_content");
                var lt_term     = getCookie("pr_lt_utm_term");
                var ft_medium   = getCookie("pr_ft_utm_medium");
                var ft_source   = getCookie("pr_ft_utm_source");
                var ft_campaign = getCookie("pr_ft_utm_campaign");
                var ft_content  = getCookie("pr_ft_utm_content");
                var ft_term     = getCookie("pr_ft_utm_term");
                var source      = getCookie("source");
                var ad_id_kwd   = getCookie("ad_id_kwd");
                // var hsutk       = getCookie("hubspotutk");
                var hs_form = {
                    "fields": [
                      {
                        "name": "email",
                        "value": $("#email").val()
                      },
                      {
                        "name": "country",
                        "value": $("#country").val()
                      },
                    //   {
                    //     "name": "content_first_touch_",
                    //     "value": ft_content
                    //   },
                    //   {
                    //     "name": "medium_first_touch_",
                    //     "value": ft_medium
                    //   },
                    //   {
                    //     "name": "source_first_touch_",
                    //     "value": ft_source
                    //   },
                    //   {
                    //     "name": "term_first_touch_",
                    //     "value": ft_term
                    //   },
                    //   {
                    //     "name": "campaign_last_touch_",
                    //     "value": lt_campaign
                    //   },
                    //   {
                    //     "name": "content_last_touch_",
                    //     "value": lt_content
                    //   },
                    //   {
                    //     "name": "medium_last_touch_1",
                    //     "value": lt_medium
                    //   },
                    //   {
                    //     "name": "source_last_touch_",
                    //     "value": lt_source
                    //   },
                    //   {
                    //     "name": "term_last_touch_",
                    //     "value": lt_term
                    //   },
                    //   {
                    //     "name": "lead_source_detail",
                    //     "value": source
                    //   },
                    //   {
                    //     "name": "ad_id_keyword",
                    //     "value": ad_id_kwd
                    //   }
                    ],
                    "context": {
                    //   "hutk"    : hsutk, // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
                      "pageUri" : window.location.href,
                      "pageName": "Blog"
                    },
                  }
                  var hs_url = "https://api.hsforms.com/submissions/v3/integration/submit/7061715/"+hsFormID
                  var hs_form_data = JSON.stringify(hs_form)
                  $.ajax({
                    type: "POST",
                    beforeSend: function(xhr) {
                      xhr.setRequestHeader( "Content-type", "application/json" );
                    },
                    url: hs_url,
                    data: hs_form_data,
                    dataType: "json"
                  }).done( function() {
                      window.dataLayer = window.dataLayer || [];
                      window.dataLayer.push({
                      'event': 'newsletter-subscribed'
                      });
                      if(typeof(redirectURL) != "undefined" && redirectURL !== null) {
                          window.location.href = redirectURL;
                      } else {
                          $('.form-loading').addClass('d-none');
                          $('.form-thanks').removeClass('d-none');
                      }
                  })
            });

        }

    };
})(jQuery, app);