(function ($, app) {
    "use strict";

    app.m52 = function () {
        if (document.querySelector('.search-result') !== null) {
            $(document).ready(function () {
                // Handler for .ready() called.
                $('html, body').animate({
                    scrollTop: $('#search-result').offset().top - 125
                }, 'slow');
            });
        }


    };
})(jQuery, app);