(function ($, app) {
    "use strict";

    app.m100 = function () {
        
        if($(".m100").length){
            $('.m100 .more').on('click', function(){
                $('.m100-icons').css('max-height', 'inherit');
                $(this).css('display', 'none');
                $('.m100 .less').css('display', 'block');
            });
            $('.m100 .less').on('click', function(){
                $('.m100-icons').css('max-height', '224px');
                $(this).css('display', 'none');
                $('.m100 .more').css('display', 'block');
            });
        }
    };
})(jQuery, app);