(function ($, app) {
    "use strict";

    app.m03 = function () {

        $('.plan-head').on('click',function(){
            if(window.innerWidth < 992){
                if($(this).parent().hasClass('expanded') || $(this).parent().hasClass('premium-expanded')){
                    $(this).parent().removeClass('expanded');
                    $(this).parent().removeClass('premium-expanded');
                }else{
                    $('.plan.expanded').removeClass('expanded');
                    $('.plan.premium-expanded').removeClass('premium-expanded');
                    $(this).parent().addClass('expanded');
                }
            }
        });
        
        $('.billing').on("click",function(){
            let $this = $(this);
            let $val = $this.attr('data-cycle');
            $('.selected_cycle').removeClass('yearlyPlan monthlyPlan').addClass($val+'Plan')
            $('.m03, .m03b').find('.price.show').each(function(){
                $(this).removeClass('show');
            });
            $('.m03, .m03b').find('.price-'+$val).addClass('show');
        });

        $(document).on('mouseover','#currency-switcher',function(){
            if(window.innerWidth >= 1200){
                $(this).next('.currencies').addClass('show');
            }
        });
        $(document).on('click','#currency-switcher',function(){
            if(window.innerWidth < 1200){
                $(this).next('.currencies').addClass('show');
            }
        });
        $(document).on('mouseleave','.currencies.show',function(){
            $('.currencies').removeClass('show');
        });
        $(document).on('click','.currencies li',function(){
            let $this = $(this);
            let $val = $this.data('value');
            let $label = $this.html();
            let $sign = $this.data('sign');
            $('.currencies li.selected').removeClass('selected');
            $this.addClass('selected');
            $('button[name="currency-switcher"]').val($val);
            $('button[name="currency-switcher"]').html($label);
            $('button[name="currency-switcher"]').attr('data-sign',$sign);
            $('.currency').html($sign);
            $('.currencies').removeClass('show');
            $('.prices-currency.show').each(function(){
                $(this).removeClass('show');
            });
            $('.prices-currency.currency-'+$val).addClass('show');
        });
        if($(".feature").length){
            $('.feature:not(.d-none):even').addClass('even-bg');
        }
        $('.feature.has-children').on('click', function() {    
            $(this).toggleClass('opened')
            $(this).find('ul').toggleClass('d-none');
            var id = $(this).find('ul').data('children');
            $('.feature-children[data-children="'+id+'"]').toggleClass('d-none')
        });
        $('.feature.has_children p.parent').on('click', function() { 
            $(this).parent().toggleClass('opened');
            var id = $(this).parent().data('children');
            $('.feature-children[data-children="'+id+'"]').toggleClass('d-none');
        });

        
    };
})(jQuery, app);