(function ($, app) {
    "use strict";

    app.m55 = function () {
      if (document.querySelector('.jobAlertModal') !== null) {
        var jobAlertModal = new bootstrap.Modal(document.getElementById('jobAlert'));
          $('#jobAlert').on('hide.bs.modal', function (event) {
            var exDate = new Date(new Date().setDate(new Date().getDate() + 90));
            document.cookie = "PlanRadarJobAlert=true; expires="  + exDate + "; path=/";
          });
          //var JobCookie = (document.cookie.match(/^(?:.*;)?\s*PlanRadarJobAlert\s*=\s*([^;]+)(?:.*)?$/)||[,null])[1];
          var distance = $('.m29').offset().top,
          $window = $(window);

          $window.scroll(function() {
            var JobCookie = (document.cookie.match(/^(?:.*;)?\s*PlanRadarJobAlert\s*=\s*([^;]+)(?:.*)?$/)||[,null])[1];
              if (!JobCookie && $window.scrollTop() >= distance ) {
                  var exDate = new Date(new Date().setDate(new Date().getDate() + 90));
                  document.cookie = "PlanRadarJobAlert=true; expires="  + exDate + "; path=/";
                  jobAlertModal.show();
                }
          });
          
        }
        function getCookie(name) {
          var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
          if (match) return match[2];
          else return 'undefined';
        }

        $(".careers-form").on("submit", function(e){
          e.preventDefault();
          $('.jobAlert-form').addClass('d-none');
          $('.form-loading').removeClass('d-none');

          var hsFormID = $(this).attr("data-hs");
          // var hsutk       = getCookie("hubspotutk");
          var hs_form = {
              "fields": [
                {
                  "name": "firstname",
                  "value": $("#alert-firstname").val()
                },
                {
                  "name": "lastname",
                  "value": $("#alert-lastname").val()
                },
                {
                  "name": "email",
                  "value": $("#alert-email").val()
                },
                {
                  "name": "privacy_policy",
                  "value": "true"
                }
              ],
              "context": {
                // "hutk"    : hsutk, // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
                "pageUri" : window.location.href,
                "pageName": "Job Alerts"
              },
            }
          var hs_url = "https://api.hsforms.com/submissions/v3/integration/submit/7061715/"+hsFormID
          var hs_form_data = JSON.stringify(hs_form)
          $.ajax({
            type: "POST",
            beforeSend: function(xhr) {
              xhr.setRequestHeader( "Content-type", "application/json" );
            },
            url: hs_url,
            data: hs_form_data,
            dataType: "json"
          }).done( function() {
              $('.form-loading').addClass('d-none');
              $('.jobAlert-success').removeClass('d-none');
          })
      });









    };
})(jQuery, app);