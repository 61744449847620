(function ($, app, _) {
    "use strict";

    app.smoothScroll = function () {

        function offsetAnchor() {
            if (location.hash.length !== 0) {
                if(app.isDesktop()) {
                    window.scrollTo(window.scrollX, window.scrollY - 130);
                }
                else {
                    window.scrollTo(window.scrollX, window.scrollY - 60);

                }
            }
        }

        $(document).on('click', 'a[href^="#"]', function(event) {
            window.setTimeout(function () {
                offsetAnchor();
            }, 0);
        });

        window.setTimeout(offsetAnchor, 0);
    };
})(jQuery, app, _);
