(function ($, app) {
    "use strict";

    app.m94 = function () {
      if(window.innerWidth < 768) {
        $('.m94 .horizontal .steps').slick({
            slidesToShow: 1.5,
            slidesToScroll: 1,
            dots: true,
            infinite: false,
            speed: 1500,
            autoplay: false,
            autoplaySpeed: 3500,
            arrows: false,
        });
      }
    };
})(jQuery, app);