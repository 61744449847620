(function ($, app) {
    "use strict";

    app.m89 = function () {
        if($(".m89").length){
            $( document ).ready(function() {
                var currentPage = $('.blog-children > li.active > span');
                var currentChildPage = $('.blog-children > li.active > .children-submenu > li > a.active');
                // $(".new-blog-mobile-nav .selected span").append(currentChildPage.text() + ' on '+ currentPage.text());
                var text = $('.new-blog-nav ul li a.active').text();
                if (currentPage.parent().index() === 0 && currentChildPage.parent().index() === 0) {
                    $(".new-blog-mobile-nav .selected span").append(currentChildPage.text()+ ' ' + $('.new-blog-mobile-nav .selected').data('articles'))
                } else if (currentChildPage.parent().index() === 0) {
                    $(".new-blog-mobile-nav .selected span").append(currentChildPage.text()+ ' ' + currentPage.text())
                } else {
                    $(".new-blog-mobile-nav .selected span").append(currentChildPage.text()+ ' on ' + currentPage.text())
                }
                if (currentPage.parent().index() === 0) {
                    return;
                }
                else if (currentPage.parent().index() === 5) {
                    $('.posts-count > p > span.name').append(currentPage.text()+ ' ')
                } else {
                    $('.posts-count > p > span.name').append(currentPage.text()+ ' ');
                    $('.posts-count > p > span.articles').hide();

                }

            });
            $('.selected').on('click', function() {
                $('.blog-children').removeClass('d-none');
                $(this).addClass('opened')
            });
            $('.blog-children li').on('click', function() {
                $(this).children("ul").removeClass('d-none');
                var navHeight = $(this).children("ul").height();
                $('.new-blog-mobile-nav').height(navHeight + 50);
                $('.blog-children').addClass('move')
            });
        }

    };
})(jQuery, app);