(function ($, app) {
    "use strict";

    app.m43 = function () {
        if($(".m43").length){
            $('.videos').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
                infinite: true,
                speed: 1500,
                autoplay: false,
                arrows: true,
                fade: true,
            });
        
            var MyPlayButton = null;

            $('.videos').on('beforeChange', function(event, slick, currentSlide, nextSlide){

                if (null !== MyPlayButton) {
                    MyPlayButton.off('click');
                }

                $("video").each(function(){
                    $(this).get(0).pause();
                });
                $(".plyr--video, .plyr--vimeo").each(function(){
                    $(this).find('.plyr__control.plyr__control--pressed').click();
                    pauseVimeo();  
                });

            });
        }
    };
})(jQuery, app);