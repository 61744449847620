(function ($, app) {
    "use strict";

    app.kb = function () {
        $('.like').on('click', function(){
            let $id = $(this).data('id');
            let $kbid = $(this).data('kbid');
            let $number = $(this).data('number');
            let $home_url = $('meta[name="msapplication-starturl"]').attr('content');
            $.ajax({
                url: $home_url+'/wp-json/pr/likes/2/'+$kbid+'/'+$number,
                type: 'post',
                success: function() {
                    console.log('works!');
                    $('#feedback_btns_'+$id).addClass('d-none');
                    $('#thanks_'+$id).removeClass('d-none');
                },
                error: function() {
                    console.log('failed!');
                }
            });
        });
        $('.dislike').on('click', function(){
            let $id = $(this).data('id');
            let $kbid = $(this).data('kbid');
            let $number = $(this).data('number');
            let $home_url = $('meta[name="msapplication-starturl"]').attr('content');
            $.ajax({
                url: $home_url+'/wp-json/pr/likes/1/'+$kbid+'/'+$number,
                type: 'post',
                success: function() {
                    console.log('works!');
                    $('#feedback_btns_'+$id).addClass('d-none');
                    $('#thanks_'+$id).removeClass('d-none');
                },
                error: function() {
                    console.log('failed!');
                }
            });
        });
        
        
        $(".kb_float_menu_btn").on("click",function(){
            $(".kb_float_menu, .kb_fm_header").removeClass('invisible');
            $(".kb_float_menu_btn").addClass('kb_fm_open');
            $(".kb_float_menu_btn_close").removeClass('d-none');
            $("body").addClass('kb_nav_opened');
          });
          $(".kb_float_menu, .kb_float_menu_btn").click(function(e){
              e.stopImmediatePropagation();
          });
        
        
          $("body, .kb_float_menu_btn_close").click(function(){
            $(".kb_float_menu, .kb_fm_header").addClass('invisible');
            $(".kb_float_menu_btn").removeClass('kb_fm_open');
            $(this).removeClass('kb_nav_opened');
            $('.kb_float_menu_btn_close').addClass('d-none');
          });
          // SHOW HASH
          $('.kb-title').click(function(){
            window.location.hash = $(this).attr("href");
          });

          // SCROLL TO CONTENT
          $('.kb_child_collapse, .kb_collapse').on('shown.bs.collapse', function(e) {
            if ($(this).is(e.target)) {
              var $card = $(this);
              $('html,body').animate({
                scrollTop: $card.offset().top - 150
              }, 500);
            }
          });

          // SHOW COLLAPSE WITH HASH
          if (location.hash !== null && location.hash !== "") {
            $(location.hash + ".collapse").collapse("show");
          }
        //   if(window.location.hash) {
        //     var hash = window.location.hash;
        //     $(hash).collapse('show');
        // }
    };
})(jQuery, app);