(function ($, app) {
    "use strict";

    app.m90 = function () {
        if($(".m90").length){

                // GET JSON URL
                var wp_json_URL = document.querySelector('link[rel="https://api.w.org/"]').href

                // DEFAULT
                var def_pid = $('.nav-blog-category li:first-child a').data('pid');
                loadRestPosts('all', 'all', def_pid);
                $('.nav-blog-subcategory li:first-child a, .nav-blog-category li:first-child a').addClass('active');
                $('.nav-blog-category li a, .new-blog-nav > .nav-blog-subcategory > .nav-item a').addClass('disabled');

                // when select category
                $('.nav-blog-category li a').on('click', function() {
                    $('.nav-blog-category li a').removeClass('active');
                    $(this).addClass('active');
                    var category = $(this).data('category');
                    var pid      = $(this).data('pid');
                    loadRestPosts(category, 'all', pid);
                    $('.nav-blog-subcategory li').each(function(index) {
                        $(this).find('a').attr('data-category', category);
                    })
                    $('.nav-blog-subcategory li a').removeClass('active');
                    $('.nav-blog-subcategory li:first-child a').addClass('active');

                    $('.nav-blog-category').addClass('move');
                    $('.nav-blog-subcategory').addClass('show');
                });
                // when select subcategory
                $('.nav-blog-subcategory li a').on('click', function() {
                    var category    = $(this).attr('data-category');
                    var subcategory = $(this).data('subcategory');
                    var pid         = $('.nav-blog-category li a.active').data('pid')
                    loadRestPosts(category, subcategory, pid);
                    $('.nav-blog-subcategory li a').removeClass('active');
                    $(this).addClass('active');
                    $('.selected-cat').removeClass('show');
                    $('.new-blog-nav').removeClass('show');
                    $('.nav-blog-subcategory').removeClass('show');
                    $('.nav-blog-category').removeClass('move').removeClass('show');
                });

                $('.selected-cat').on('click', function() {
                    $('.nav-blog-category').addClass('show');
                    $('.selected-cat').addClass('show');
                    $('.new-blog-nav').addClass('show');
                });

                function loadRestPosts (pr_category, pr_subcategory, pr_pid) {
                    $('.nav-blog-category li a, .new-blog-nav > .nav-blog-subcategory > .nav-item a').addClass('disabled');
                    $('.m90').addClass('loading');
                    $.getJSON(wp_json_URL + 'pr/v2/blog/?category=' + pr_category + '&subcategory=' + pr_subcategory + '&pid='+pr_pid ,function(data) {
                        if ( data.length == 0 ) {
                            var nothing2Show = ''+
                                            '<p class="text-center">Nothing to see here</p>';
                            $('.category .subcategory .row').html(nothing2Show);
                            $('.nav-blog-category li a, .new-blog-nav > .nav-blog-subcategory > .nav-item a').removeClass('disabled');
                            $('.m90').removeClass('loading');
                        } else {
                            $('.nav-blog-subcategory li').not(':first-child').each(function() {
                                var data_subcategory = $(this).children('a').data('subcategory');
                                if(typeof data.subcategories[data_subcategory] != "undefined") {
                                    $(this).attr('data-count', data.subcategories[data_subcategory])
                                } else {
                                    $(this).attr('data-count', 0)
                                }
                                
                            });
                            var posts = '<div class="col-md-6"><p class="bar-title">'+ data.title +'</p></div>'+
                                        '<div class="col-md-6"><p class="posts-count">'+ data.count +'</p></div>';
                            $.each(data.posts, function(i, post) {
                                posts += ''+
                                '<div class="col-md-6 col-lg-4">'+
                                    '<a href="'+ post.permalink +'" title="'+ post.title +'">'+
                                        '<div class="new-blog-post">'+
                                            '<div class="blog-img">'+
                                                '<img class="img-fluid" src="'+ post.img +'" alt="'+ post.title +'" width="315" height="208">'+
                                            '</div>'+
                                            '<p class="new-post-cat">'+ post.bubble +'</p>'+
                                            '<p class="new-post-title">'+ post.title +'</p>'+
                                            '<div class="new-post-content">' + post.desc + '</div>'+
                                            '<div class="new-post-data">'+
                                                '<p class="estimated">'+
                                                    ((post.reading !== null) ?  '<i class="fa fa-clock-o" aria-hidden="true"></i> ' + post.reading  : '')
                                                +'</p>'+
                                                '<p class="read-more">'+post.more+'</p>'+
                                            '</div>'+
                                        '</div>'+
                                    '</a>'+
                                '</div>';
                            });
                            posts += '<div class="blog-button">'+
                                        ' <a href="'+data.link+'" class="btn blue-btn" title="'+data.button+'">'+data.button+'</a>'+
                                    '</div>';
                            $('.category .subcategory .row').html(posts);
                            $('.nav-blog-category li a, .new-blog-nav > .nav-blog-subcategory > .nav-item a').removeClass('disabled');
                            $('.m90').removeClass('loading');
                            $('.selected-cat span').html(data.title);
                        }
                    });
                    
                }
        }
    };
})(jQuery, app);