/* responsive module */

(function ($, app) {
    "use strict";

    app.setDimensions = function () {

        $(window).resize(function () {
            app.windowWidth = $(window).width();
            app.windowHeight = $(window).height();
            app.windowCenterY = Math.round(app.windowWidth / 2);
            app.windowCenterX = Math.round(app.windowHeight / 2);
        }).trigger('resize');
    };

    app.isDesktop = function () {
        return (app.windowWidth > 991);
    };

    app.isMobile = function () {
        return (app.windowWidth <= 991);
    };

})(jQuery, app);