(function ($, app) {
    "use strict";

    app.w01 = function () {
        
        if($(window).width() >= 1199) {
            if (document.querySelector('.w01') !== null && document.querySelector('.m90-blog') !== null) {
                $('.w01').insertBefore('.explore-content');
            }
            if (document.querySelector('.w01') !== null) {
                $('.w01').insertBefore('.sidebar-featured');
            }
            if (document.querySelector('.w02') !== null) {
                $('.w02').insertBefore('.sidebar-featured');
            }
        }
        $('.w01_next_step').on('click', function() {
            $('.w01').addClass('w01-opened')
            $('.w01-step1').addClass('d-none');
            $('.w01-step2').removeClass('d-none')
        });
    };
})(jQuery, app);