/* responsive module */

(function ($, app) {
    "use strict";

    app.m01d = function () {
        $("#m01d-modal").on("show.bs.modal", function () { 
            modalPlayer.play()
        });
        $("#m01d-modal").on("hide.bs.modal", function () { 
            modalPlayer.pause()
        });
    };
})(jQuery, app);