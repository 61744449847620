(function ($, app) {
    "use strict";
    app.m37 = function () {
        $(".m37-slider").mouseenter(function() {
            $('.m37-slider > .slick-prev').addClass('showSliderArrow');
            $('.m37-slider > .slick-next').addClass('showSliderArrow');
        }).mouseleave(function() {
            $('.m37-slider > .slick-prev').removeClass('showSliderArrow');
            $('.m37-slider > .slick-next').removeClass('showSliderArrow');
        });
        $(window).ready(function(){
            if($('.m37-slider').length){
                $('.m37-slider').addClass('loaded');
            }
        });
    };
})(jQuery, app);