(function ($, app) {
    "use strict";
    app.m36 = function () {
        $('.mtt-col').on('click', function() {
            $('.mtt-popup').addClass('d-none')
            $('body').addClass('mtt-popup-opened')
            $(this).next().removeClass('d-none')
        });

        $('.mtt-popup_close').on('click', function() {
            $('.mtt-popup').addClass('d-none');
            $('body').removeClass('mtt-popup-opened');
        });

        $(document).on('click', function (e) {
            if ($(e.target).closest(".mtt-row").length === 0) {
                $('.mtt-popup').addClass('d-none');
                $('body').removeClass('mtt-popup-opened');
            }
        });
        $(document).ready(function(){
            var firstDep = $('.department-filter button').first().data("department");
            $('.mtt-col.'+firstDep).removeClass('d-none');
            $('.department-filter button').first().addClass('active');
        });
        
        //DESKTOP FILTER
        $('.department-filter button').on('click', function() {
            $('.department-filter button').removeClass('active');
            $(this).addClass('active');
            var department = $(this).data("department");
            $('.mtt-col').addClass('d-none');
            $('.mtt-col.'+department).removeClass('d-none');
        });

        //MOBILE FILTER
        $('.department-select').on('change', function() {
            $('.mtt-col').addClass('d-none');
            $('.mtt-col.'+this.value).removeClass('d-none');
          });
        
    };
})(jQuery, app);